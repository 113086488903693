@import 'components/functions'
@import 'components/globals/variables'

// Swiper
.swiper
    overflow: visible !important

.swiper-pagination-bullets
    display: flex

    .swiper-pagination
        &-bullet
            box-shadow: $shadow
            border: 1px solid $color-gray-light
            width: tr(16)
            height: tr(4)
            display: block
            background: $color-gray-light
            transition: all .2s ease-in-out
            border-radius: $rounded-large
            margin: 0 tr(4)
    
            &-active
                border-color: $color-accent
                width: tr(32)
                background-color: $color-accent
.swiper-button-next,
.swiper-button-prev
    display: none

// Datepicker
@import 'node_modules/air-datepicker/dist/css/datepicker'


.datepicker--cell.-current-
    color: $color-secondary

.datepicker--cell.-selected-
    background-color: $color-accent

.datepicker--day-name
    color: $color-accent

// Select2
.select2-container
    width: 100% !important
    
    .select2-selection
        border: none !important

        &:focus-visible
            outline: none !important

        &.select2-selection--single
            background-color: $color-gray-lightest
            border: tr(1) solid $color-gray-lightest
            border-radius: $rounded-small 
            height: tr(48)
            display: flex
            align-items: center

        .select2-selection__rendered
            @extend .input !optional
            line-height: 1 !important
            padding-left: tr(16) !important
            padding-right: tr(48) !important
        
        .select2-selection__arrow
            display: none !important
    
    // Jika Dropdown Terbuka
    // &.select2-container--open
    //     .select2-selection
    //         .select2-selection__rendered
    //             border: tr(1) solid var(--rckryd-primary) !important
        
        // Jika Dropdown Kebawah
        // &.select2-container--below
        //     .select2-selection
        //         .select2-selection__rendered
        //             border-bottom-left-radius: 0
        //             border-bottom-right-radius: 0
        //             border-bottom: tr(1) solid transparent !important
    
    // Dropdown Body
    .select2-dropdown
        border: none
        box-shadow: $shadow20

        .select2-search.select2-search--dropdown
            padding: tr(12) tr(8)

            .select2-search__field
                @extend .input !optional
                padding: tr(10) tr(16)
                border-radius: tr(4)

                &:focus-visible
                    outline: none !important
                    

        .select2-results__options
            padding: tr(8) tr(8)

            .select2-results__option
                padding: tr(12) tr(16)
                border-radius: tr(4)

                &.select2-results__option--highlighted
                    background-color: transparent
                    color: $color-accent

                &.select2-results__option--selected
                    background-color: $color-accent
                    color: white
            

            // &.select2-dropdown--above
            
            // Jika Dropdown Kebawah
            // &.select2-dropdown--below
            //     .select2-search.select2-search--dropdown
            //         margin-bottom: tr(8)

// slider
.slider-navigator
    @media (max-width: 991.98px)
        display: none
        
    label
        color: $color-accent
        font-size: tr(56)
        cursor: pointer
        transition: all .3s $cbzr
        backface-visibility: hidden

        &:not(:last-child)
            margin-right: tr(24)
        
        &:hover
            transform: scale(1.1)
            opacity: .8

// Leaflet
.leaflet-container
    z-index: 1 !important